import { jsx as _jsx } from "react/jsx-runtime";
import { createColumn } from "@components";
import SiteRoles from "./SiteRoles";
const userEditRolesDictionary = {
    2: "Site Administrator",
    3: "Job Designer",
    4: "Consumer",
};
const userRolesDictionary = { 1: "Tenant Administrator", ...userEditRolesDictionary };
const sortedRolesDictionaryKeys = Object.keys(userEditRolesDictionary)
    .map(Number)
    .sort((keyA, keyB) => keyB - keyA);
const createRoleColumn = (input) => {
    const { columnId, headerText, siteId, badge, dispatchMessage } = input;
    if (!siteId) {
        throw new Error("siteId is required to create role column");
    }
    const createRole = (item, columnId) => (_jsx(SiteRoles, { roles: item[columnId], siteId: siteId, principalId: item.id, dispatchMessage: dispatchMessage }));
    return createColumn(columnId, headerText, createRole, false, badge);
};
export { createRoleColumn, sortedRolesDictionaryKeys, userEditRolesDictionary, userRolesDictionary };
