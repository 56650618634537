import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { makeStyles, mergeClasses, shorthands, tokens } from "@fluentui/react-components";
import { AddButton } from "@components";
import { useUserRoles } from "@hooks";
import { rootPage as useRootStyles } from "@styles";
import { SiteSelector } from "../siteSelector";
import { PrincipalContainer } from "./PrincipalContainer";
const useStyles = makeStyles({
    root: {
        height: "fill-available",
        ...shorthands.overflow("hidden"),
    },
    siteSelectorWrapper: {
        position: "absolute",
        top: "3rem",
        height: "3rem",
        display: "flex",
        alignItems: "center",
    },
    siteSelector: {
        backgroundColor: tokens.colorNeutralBackground2,
        paddingLeft: "1rem",
    },
});
const UsersRolesContent = () => {
    const { tenantAdmin } = useUserRoles();
    const rootStyles = useRootStyles();
    const styles = useStyles();
    const [showAddPrincipalDialog, setShowAddPrincipalDialog] = useState(false);
    return (_jsxs("div", { className: mergeClasses(rootStyles.root, styles.root), children: [_jsxs("div", { className: styles.siteSelectorWrapper, children: [tenantAdmin && (_jsx(AddButton, { text: "Add Users", onClick: () => {
                            setShowAddPrincipalDialog(true);
                        } })), _jsx("div", { className: styles.siteSelector, children: _jsx(SiteSelector, {}) })] }), _jsx("div", { className: rootStyles.body, children: _jsx(PrincipalContainer, { showAddPrincipalDialog: showAddPrincipalDialog, setShowAddPrincipalDialog: setShowAddPrincipalDialog }) })] }));
};
export { UsersRolesContent };
