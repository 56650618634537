import { jsx as _jsx } from "react/jsx-runtime";
import { Button, makeStyles, mergeClasses } from "@fluentui/react-components";
import { Add20Regular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    button: {
        paddingLeft: "0",
    },
    customText: {
        "& span": {
            marginLeft: "0.4rem",
        },
    },
});
const AddButton = ({ text, onClick }) => {
    const styles = useStyles();
    const buttonText = text !== null && text !== void 0 ? text : "Add";
    return (_jsx(Button, { className: mergeClasses(styles.button, text && styles.customText), "data-testid": "add-button", appearance: "primary", size: "medium", icon: _jsx(Add20Regular, {}), onClick: onClick, children: buttonText }));
};
export default AddButton;
