import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "@lib/redux";
import { SitesDropdown } from "@components";
import { useUpdateSiteSelected } from "../../hooks";
const SiteSelector = () => {
    const { sites } = useSelector((state) => state.tenant);
    const sitesOptions = sites ? sites.map((site) => { var _a, _b; return ({ text: (_a = site.name) !== null && _a !== void 0 ? _a : "", id: (_b = site.id) !== null && _b !== void 0 ? _b : "" }); }) : [];
    const [updateSiteSelected] = useUpdateSiteSelected();
    const onOptionSelect = (value) => {
        var _a;
        const newSelectedSite = (_a = sites === null || sites === void 0 ? void 0 : sites.find((site) => site.id === value)) !== null && _a !== void 0 ? _a : { id: "", name: "", description: "" };
        updateSiteSelected(newSelectedSite);
    };
    return (_jsx(SitesDropdown, { options: sitesOptions, onOptionSelect: onOptionSelect, showAllSitesOption: true, selectAllSitesByDefault: true }));
};
export default SiteSelector;
