import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from "react";
import { usePrincipalLoading } from "../../hooks";
import { makeStyles, shorthands, tokens, Spinner, Title1 } from "@fluentui/react-components";
import { ErrorDisplay } from "@features";
import { rootPage as useRootStyles, settingsPage as useSettingsStyles } from "@styles";
import { TableSection } from "./TableSection";
import { RolTabs } from "./RolTabs";
import { AddPrincipals } from "../addPrincipals";
const useStyles = makeStyles({
    tenantAdminInfoText: {
        color: tokens.colorNeutralForeground4,
        fontSize: "0.625rem",
        marginLeft: "0.625rem",
        ...shorthands.padding("0.5rem", "1rem"),
    },
    italic: {
        fontStyle: "italic",
    },
});
const PrincipalContainer = memo(({ setShowAddPrincipalDialog, showAddPrincipalDialog }) => {
    const rootStyles = useRootStyles();
    const styles = useStyles();
    const settingsStyles = useSettingsStyles();
    const [loading, principals, error] = usePrincipalLoading();
    return (_jsx(_Fragment, { children: loading ? (_jsx(Spinner, { label: "Loading...", size: "huge", className: rootStyles.spinner })) : error ? (_jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } })) : (_jsxs("div", { className: rootStyles.tabList, children: [_jsx("div", { className: settingsStyles.header }), _jsx(Title1, { className: rootStyles.pageTitle, children: "Users & Roles" }), showAddPrincipalDialog && (_jsx(AddPrincipals, { onDismiss: () => {
                        setShowAddPrincipalDialog(false);
                    }, principalsInSystem: principals })), _jsx(RolTabs, {}), _jsx(TableSection, {}), _jsxs("div", { className: styles.tenantAdminInfoText, children: ["Tenant Admins are managed on a separate page. Please navigate to the", " ", _jsx("span", { className: styles.italic, children: "General" }), " section."] })] })) }));
});
export { PrincipalContainer };
