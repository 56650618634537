import { useEffect, useMemo } from "react";
import { getMeInfo } from "@services/api";
import { getIsObsolete, setUserInfo, UserRoles, useDispatch, useSelector } from "@lib/redux";
let fetchingUserInfo = false;
const useUserRoles = () => {
    const dispatch = useDispatch();
    const roleAssignments = useSelector((state) => state.userInfo.roleAssignments);
    const tenantId = useSelector((state) => state.tenant.id);
    const selectedSiteId = useSelector((state) => { var _a; return (_a = state.appState.selectedSite) === null || _a === void 0 ? void 0 : _a.id; });
    // Memoizing the roles to prevent unnecessary re-renders
    const tenantAdmin = useMemo(() => {
        return roleAssignments.some((role) => role.roleId === UserRoles.TenantAdmin);
    }, [roleAssignments]);
    const siteAdmin = useMemo(() => {
        return roleAssignments.some((role) => role.roleId === UserRoles.SiteAdmin && role.siteId === selectedSiteId);
    }, [roleAssignments, selectedSiteId]);
    const jobDesigner = useMemo(() => {
        return roleAssignments.some((role) => role.roleId === UserRoles.JobDesigner && role.siteId === selectedSiteId);
    }, [roleAssignments, selectedSiteId]);
    const consumer = useMemo(() => {
        return roleAssignments.some((role) => role.roleId === UserRoles.Consumer && role.siteId === selectedSiteId);
    }, [roleAssignments, selectedSiteId]);
    useEffect(() => {
        const checkIfRolesAreObsolete = async () => {
            if (getIsObsolete() && !fetchingUserInfo) {
                // Fetch new user info here
                if (tenantId) {
                    try {
                        fetchingUserInfo = true;
                        console.log("User info is obsolete, fetching new user info...");
                        const data = await getMeInfo(tenantId);
                        fetchingUserInfo = false;
                        // Update the Redux store state with the new user info
                        await dispatch(setUserInfo(data.data.me));
                    }
                    catch (error) {
                        console.error("Error fetching user info in the useUserRoles hook: ", error);
                        throw error;
                    }
                }
            }
        };
        checkIfRolesAreObsolete();
    }, [tenantId, dispatch]);
    return {
        tenantAdmin,
        siteAdmin,
        jobDesigner,
        consumer,
    };
};
export default useUserRoles;
