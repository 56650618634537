import { useContext, useEffect, useState } from "react";
import { StatusQueryContext } from "../components/principalProvider";
import { PrincipalType } from "@types";
const getPrincipalType = (type) => {
    if (type === "USER") {
        return PrincipalType.USER;
    }
    else {
        return PrincipalType.SERVICE_PRINCIPAL;
    }
};
const usePrincipalLoading = () => {
    const { loading: loadingQuery, principals: principalsFromApi, error: errorQuery } = useContext(StatusQueryContext);
    const [loading, setLoading] = useState(loadingQuery);
    const [error, setError] = useState(errorQuery);
    const [principals, setPrincipals] = useState([]);
    const formatPrincipals = (principals) => principals.map((principal) => ({
        id: principal.id,
        displayName: principal.displayName,
        email: principal.email,
        principalType: getPrincipalType(principal.principalType),
        roleAssignments: principal.roleAssignments,
    }));
    useEffect(() => {
        if (loadingQuery !== loading) {
            setLoading(loadingQuery);
        }
    }, [loadingQuery]);
    useEffect(() => {
        if ((errorQuery === null || errorQuery === void 0 ? void 0 : errorQuery.message) !== (error === null || error === void 0 ? void 0 : error.message)) {
            setError(errorQuery);
        }
    }, [errorQuery]);
    useEffect(() => {
        if (principalsFromApi) {
            setPrincipals(formatPrincipals(principalsFromApi));
        }
    }, [principalsFromApi]);
    return [loading, principals, error];
};
export { usePrincipalLoading };
