import { gql, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { sessionStorageKeyAddPrincipalsModalOpen, useGetPrincipals } from "../components/addPrincipals";
const QUERY = gql `
  query principals($tenantIdOrKey: String!) {
    principals(tenantIdOrKey: $tenantIdOrKey) {
      displayName
      email
      id
      principalType
      roleAssignments {
        principalId
        roleId
        siteId
        tenantId
      }
    }
  }
`;
/**
 * Custom hook to query principals based on the tenant ID.
 *
 * This hook fetches principals from an API using GraphQL and manages the state of the principals.
 * It also integrates with Azure to fetch additional principal details if needed.
 *
 * @returns {useQueryPrincipalsReturnType} An object containing:
 * - `principals` {Principal[]} - The list of principals.
 * - `done` {boolean} - A flag indicating if the data fetching is complete.
 * - `loading` {boolean} - A flag indicating if the GraphQL query is in progress.
 * - `loadingPrincipalsFromAzure` {boolean} - A flag indicating if the Azure principals fetching is in progress.
 * - `error` {ApolloError | undefined} - Any error encountered during the GraphQL query.
 * - `refetch` {function} - A function to refetch the principals data.
 */
const useQueryPrincipals = () => {
    const { id: tenantId } = useSelector((state) => state.tenant);
    const [principals, setPrincipals] = useState([]);
    const [principalsFromApi, setPrincipalsFromApi] = useState([]); // When API will return all the principals with their display names this useState must be removed
    const [showAddPrincipalDialog, setShowAddPrincipalDialog] = useState(false);
    const [done, setDone] = useState(false);
    // When API will return all the principals with their display names this useGetPrincipals hook must be removed
    const { principals: principalsFromAzure, loading: loadingPrincipalsFromAzure } = useGetPrincipals();
    const { loading, error, refetch } = useQuery(QUERY, {
        variables: { tenantIdOrKey: tenantId },
        onCompleted: async (data) => {
            // When API will return all the principals with their display names principals must be set and removed setPrincipalsFromApi
            // setPrincipals(data.principals);
            setPrincipalsFromApi(data.principals);
        },
    });
    useEffect(() => {
        if (!loadingPrincipalsFromAzure && sessionStorage.getItem(sessionStorageKeyAddPrincipalsModalOpen) === "true") {
            setShowAddPrincipalDialog(true);
            sessionStorage.removeItem(sessionStorageKeyAddPrincipalsModalOpen);
        }
    }, [loadingPrincipalsFromAzure]);
    // useEffect(() => {
    //   setGroupedPrincipals(groupPrincipals());
    // }, [selectedUsersGroup, selectedSite, principals]);
    useEffect(() => {
        // When API will return all the principals with their display names this useEffect must be removed
        if (!loadingPrincipalsFromAzure) {
            if (principalsFromAzure.length > 0 && principalsFromApi.length > 0) {
                setPrincipals(principalsFromApi.map((principal) => {
                    var _a;
                    if (principal.displayName) {
                        return principal;
                    }
                    const azureTenantId = principal.id.split("|")[1];
                    const azureObjectId = principal.id.split("|")[2];
                    const azureDisplayName = (_a = principalsFromAzure.find((p) => p.azureTenantId === azureTenantId && p.id === azureObjectId)) === null || _a === void 0 ? void 0 : _a.displayName;
                    return {
                        ...principal,
                        displayName: azureDisplayName !== null && azureDisplayName !== void 0 ? azureDisplayName : "",
                    };
                }));
            }
            else if (principalsFromApi.length > 0) {
                setPrincipals(principalsFromApi);
            }
            setDone(true);
        }
    }, [principalsFromAzure, principalsFromApi, loadingPrincipalsFromAzure]);
    return { principals, done, loading, loadingPrincipalsFromAzure, error, refetch };
};
export { useQueryPrincipals };
