import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef, useCallback } from "react";
import { DataGrid, DataGridBody, DataGridCell, DataGridHeaderCell, DataGridHeaderRow, } from "@fluentui-contrib/react-data-grid-react-window-grid";
import { DataGridHeader, tokens, useFluent, useScrollbarWidth } from "@fluentui/react-components";
import { usePrincipalList } from "../../hooks";
const cellHeight = 45;
const cellWidth = 370;
const PrincipalList = ({ columns, parentRef }) => {
    const highlightedContainerRef = useRef(null);
    const principalListId = "principalList";
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [parentOffset, setParentOffset] = useState({
        width: 1000,
        height: 500,
    });
    const [sortState, setSortState] = useState({
        sortColumn: "displayName",
        sortDirection: "ascending",
    });
    const [principals, updateResizeTable] = usePrincipalList();
    const onSortChange = (_e, nextSortState) => {
        setSortState(nextSortState);
    };
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setParentOffset({
                    width: parentRef.current.offsetWidth - 15 - (scrollbarWidth !== null && scrollbarWidth !== void 0 ? scrollbarWidth : 0),
                    height: parentRef.current.offsetHeight - 50,
                });
            }
        };
        handleResize();
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        if (parentRef.current) {
            resizeObserver.observe(parentRef.current);
        }
        // Clean up
        return () => {
            if (parentRef.current) {
                resizeObserver.unobserve(parentRef.current);
            }
            resizeObserver.disconnect();
        };
    }, []);
    useEffect(() => {
        if (principals.length > 0) {
            const sheet = new CSSStyleSheet();
            for (let i = 2; i < principals.length + 2; i++) {
                sheet.insertRule(`#${principalListId} div[aria-rowindex="${i}"]{ background-color: var(--hover-principal-row-${i}); }`, 0);
            }
            document.adoptedStyleSheets = [sheet];
        }
    }, [principals]);
    useEffect(() => {
        const randomToken = Math.random() * (parentOffset.width + parentOffset.height);
        updateResizeTable(randomToken);
    }, [parentOffset]);
    const getRowId = (element) => {
        if (!element) {
            return null;
        }
        const rowId = element.getAttribute("aria-rowindex");
        if (rowId === null) {
            return getRowId(element.parentElement);
        }
        return rowId;
    };
    const cellRenderer = useCallback(({ item }, column, style) => {
        const onMouseEnter = (event) => {
            var _a;
            const rowId = getRowId(event.target);
            (_a = highlightedContainerRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty(`--hover-principal-row-${rowId}`, tokens.colorSubtleBackgroundHover);
        };
        const onMouseLeave = (event) => {
            var _a;
            const rowId = getRowId(event.target);
            (_a = highlightedContainerRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty(`--hover-principal-row-${rowId}`, "");
        };
        return (_jsx(DataGridCell, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, style: { ...style, boxSizing: "border-box" }, children: column.renderCell(item) }));
    }, []);
    return (_jsx("div", { id: principalListId, ref: highlightedContainerRef, children: _jsxs(DataGrid
        // To ensure that DataGrid recalculates the column widths, we use the parent
        // width as a key. This forces a re-render of DataGrid when the parent width changes.
        , { 
            // To ensure that DataGrid recalculates the column widths, we use the parent
            // width as a key. This forces a re-render of DataGrid when the parent width changes.
            noNativeElements: true, items: principals, columns: columns, size: "medium", sortable: true, sortState: sortState, onSortChange: onSortChange, children: [_jsx(DataGridHeader, { children: _jsx(DataGridHeaderRow, { itemSize: () => cellWidth, height: cellHeight, width: parentOffset.width, children: ({ renderHeaderCell }, style) => (_jsx(DataGridHeaderCell, { as: "div", style: { ...style, boxSizing: "border-box" }, children: renderHeaderCell(style.width) })) }) }), _jsx(DataGridBody, { rowHeight: () => cellHeight, height: parentOffset.height, width: parentOffset.width, columnWidth: () => cellWidth, children: cellRenderer })] }, parentOffset.width) }));
};
export default PrincipalList;
