import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PrincipalList } from "../principalList";
import { createPersonaColumn, createRoleColumn } from "../principalList/components";
import { useDispatchMessageToast } from "@components";
import { makeStyles, tokens, shorthands } from "@fluentui/react-components";
import { useSelectedSite } from "../../hooks";
const useStyles = makeStyles({
    principalListWrapper: {
        height: "calc(100vh - 19rem)",
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.padding("0.5rem", "1rem"),
        ...shorthands.borderRadius("0.5rem"),
        marginTop: "1rem",
        marginLeft: "0.625rem",
        overflowY: "hidden",
    },
});
const TableSection = () => {
    const styles = useStyles();
    const divRef = useRef(null);
    const [columnsSites, setColumnsSites] = useState([]);
    const [columnsSite, setColumnsSite] = useState([]);
    const { sites } = useSelector((state) => state.tenant);
    const { dispatchMessageToast, toasterElement } = useDispatchMessageToast("PrincipalList");
    const [selectedSite] = useSelectedSite();
    const columns = [createPersonaColumn("displayName", "User", true)];
    useEffect(() => {
        const columnsSites = [...columns];
        if (sites) {
            sites.forEach((site) => {
                columnsSites.push(createRoleColumn({
                    columnId: "roleAssignments",
                    headerText: site.name,
                    siteId: site.id,
                    badge: true,
                    dispatchMessage: dispatchMessageToast,
                }));
            });
            setColumnsSites(columnsSites);
        }
    }, [sites]);
    useEffect(() => {
        if (selectedSite.id !== "") {
            const columnsSite = [...columns];
            columnsSite.push(createRoleColumn({
                columnId: "roleAssignments",
                headerText: "Roles",
                siteId: selectedSite.id,
                badge: false,
                dispatchMessage: dispatchMessageToast,
            }));
            setColumnsSite(columnsSite);
        }
    }, [selectedSite]);
    return (_jsxs("div", { className: styles.principalListWrapper, ref: divRef, children: [toasterElement, selectedSite.id ? (_jsx(PrincipalList, { columns: columnsSite, parentRef: divRef })) : (_jsx(PrincipalList, { columns: columnsSites, parentRef: divRef }))] }));
};
export { TableSection };
