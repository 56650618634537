import { useState, useEffect, useContext } from "react";
import { gql, useMutation } from "@lib/apolloClient";
import { useSelectorCustom } from "@lib/redux";
import { AddRoleContext } from "../components/principalProvider/principalProvider";
const ADD_ROLE = gql `
  mutation AddRoleAssignment($input: AddRoleAssignmentInput!) {
    addRoleAssignment(input: $input) {
      roleAssignment {
        principalId
        siteId
        roleId
        tenantId
      }
    }
  }
`;
const useAddRole = () => {
    const tenantIdOrKey = useSelectorCustom("tenant.id");
    const [addMutation, { data: dataMutation, loading: loadingMutation, error: errorMutation, client }] = useMutation(ADD_ROLE);
    const [succeeded, setSucceeded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(null);
    const { addRole } = useContext(AddRoleContext);
    const refetch = () => {
        client === null || client === void 0 ? void 0 : client.refetchQueries({ include: ["principals"] });
    };
    const addRoleMutation = async (inputs) => {
        const addOneRole = async (inputs) => {
            const { siteId: siteIdOrName, principalId, roleId } = inputs;
            try {
                await addMutation({
                    variables: {
                        input: {
                            tenantIdOrKey,
                            siteIdOrName,
                            principalId,
                            roleId,
                        },
                    },
                });
            }
            catch (e) {
                setSucceeded(false);
            }
        };
        const addArrayOfRoles = async (input) => {
            const { siteId: siteIdOrName, principalId, roleIds } = input;
            setSucceeded(false);
            try {
                await Promise.all(roleIds.map((roleId) => addMutation({
                    variables: {
                        input: {
                            tenantIdOrKey,
                            siteIdOrName,
                            principalId,
                            roleId,
                        },
                    },
                })));
            }
            catch (e) {
                setSucceeded(false);
            }
        };
        if ("roleIds" in inputs) {
            await addArrayOfRoles(inputs);
        }
        else {
            await addOneRole(inputs);
        }
    };
    useEffect(() => {
        var _a;
        const succeeded = !!(dataMutation === null || dataMutation === void 0 ? void 0 : dataMutation.addRoleAssignment);
        if (dataMutation && ((_a = dataMutation === null || dataMutation === void 0 ? void 0 : dataMutation.addRoleAssignment) === null || _a === void 0 ? void 0 : _a.roleAssignment)) {
            setData(dataMutation.addRoleAssignment.roleAssignment);
            if (succeeded) {
                addRole(dataMutation.addRoleAssignment.roleAssignment);
            }
        }
        setSucceeded(succeeded);
    }, [dataMutation]);
    useEffect(() => {
        if (loading !== loadingMutation) {
            setLoading(loadingMutation);
        }
    }, [loadingMutation]);
    useEffect(() => {
        if (error !== errorMutation) {
            setError(errorMutation);
            setSucceeded(false);
        }
    }, [errorMutation]);
    return [addRoleMutation, { loading, error, data, succeeded, refetch }];
};
export { useAddRole };
