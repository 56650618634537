import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from "react";
import { makeStyles, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, mergeClasses, shorthands, Spinner, tokens, } from "@fluentui/react-components";
import { AddRegular, CheckmarkFilled } from "@fluentui/react-icons";
import { sortedRolesDictionaryKeys, userEditRolesDictionary } from "./roleUtils";
import { useAddRole } from "../../../../hooks";
const useStyles = makeStyles({
    button: {
        color: tokens.colorNeutralForeground3,
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.border("none"),
        height: "2rem",
        minWidth: "1.5rem",
        maxWidth: "1.5rem",
        "& svg": {
            fontSize: "1rem",
        },
    },
});
const AddRolesButton = memo(({ roles, siteId, principalId, onAdd, dispatchMessage }) => {
    const styles = useStyles();
    const refetchNecessary = () => { };
    const [addRoleMutation, { loading, data, error, succeeded }] = useAddRole();
    const [roleAdded, setRoleAdded] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    useEffect(() => {
        if (succeeded) {
            dispatchMessage(`${userEditRolesDictionary[data.roleId]} role added`, "success");
            const role = {
                principalId: data.principalId,
                siteId: data.siteId,
                roleId: data.roleId,
                tenantId: data.tenantId,
            };
            onAdd(role);
            // addRole(role);
            refetchNecessary();
        }
    }, [succeeded, data]);
    const onAddRole = (roleId) => {
        setRoleAdded(roleId);
        addRoleMutation({
            principalId,
            siteId,
            roleId,
        });
    };
    useEffect(() => {
        if (error && roleAdded) {
            dispatchMessage(`Error adding ${userEditRolesDictionary[roleAdded]} role`, "error");
            setRoleAdded(null);
        }
    }, [error]);
    if (roles.length === sortedRolesDictionaryKeys.length) {
        // Object.keys(userEditRolesDictionary).length) {
        return null;
    }
    return (_jsxs(Menu, { positioning: "below-end", open: menuOpen, onOpenChange: (e, { open }) => setMenuOpen(open), children: [_jsx(MenuTrigger, { disableButtonEnhancement: true, children: _jsx(MenuButton, { className: mergeClasses(styles.button), "data-testid": "add-roles-button", icon: loading ? _jsx(Spinner, { size: "extra-tiny" }) : _jsx(AddRegular, {}) }) }), _jsx(MenuPopover, { children: menuOpen && (_jsx(MenuList, { hasIcons: true, children: sortedRolesDictionaryKeys.map((roleId) => {
                        const alreadyInRoles = roles.some((role) => role.roleId === roleId);
                        return (_jsx(MenuItem, { icon: alreadyInRoles ? _jsx(CheckmarkFilled, {}) : _jsx("div", {}), "data-testid": `add-role-menu-${roleId}`, disabled: alreadyInRoles, content: userEditRolesDictionary[roleId], onClick: () => onAddRole(roleId) }, roleId));
                    }) })) })] }));
});
export default memo(AddRolesButton);
