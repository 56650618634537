import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, MessageBar, MessageBarActions, MessageBarBody, Tooltip } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { redirectToGrantAdminPermissions } from "@lib/msal-browser";
import { sessionStorageKeyAddPrincipalsModalOpen } from "../utils/utils";
const toolTipUserGrantMessage = "Allows the app to read a basic set of profile properties of other users in your organization on your behalf.";
const toolTipAppGrantMessage = "Allows the app to read applications and service principals on your behalf. Only an Admin can grant it.";
const MessagePanel = ({ type, message, onDismiss, userAuth, appAuth }) => {
    const handleGrantAdminPermissions = (type) => {
        sessionStorage.setItem(sessionStorageKeyAddPrincipalsModalOpen, "true");
        return redirectToGrantAdminPermissions(type);
    };
    return (_jsxs(MessageBar, { intent: type, children: [_jsx(MessageBarBody, { "data-testid": "grant-message", children: message }), _jsxs(MessageBarActions, { containerAction: onDismiss && (_jsx(Button, { "aria-label": "dismiss", appearance: "transparent", icon: _jsx(DismissRegular, {}), onClick: onDismiss })), children: [!userAuth && type === "warning" && (_jsx(Tooltip, { content: toolTipUserGrantMessage, relationship: "description", positioning: "below", withArrow: true, children: _jsx(Button, { appearance: "primary", "data-testid": "grant-users-button", onClick: () => handleGrantAdminPermissions("user"), children: "Grant Users" }) })), !appAuth && type === "warning" && (_jsx(Tooltip, { content: toolTipAppGrantMessage, relationship: "description", positioning: "below", withArrow: true, children: _jsx(Button, { appearance: "primary", "data-testid": "grant-apps-button", onClick: () => handleGrantAdminPermissions("app"), children: "Grant Apps" }) }))] })] }));
};
export default MessagePanel;
