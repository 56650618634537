import { useState, useEffect, useContext } from "react";
import { gql, useMutation } from "@lib/apolloClient";
import { useSelectorCustom } from "@lib/redux";
import { RemoveRoleContext } from "../components/principalProvider/principalProvider";
const DELETE_ROLE = gql `
  mutation RemoveRoleAssignment($input: RemoveRoleAssignmentInput!) {
    removeRoleAssignment(input: $input) {
      roleAssignment {
        principalId
        roleId
        siteId
      }
    }
  }
`;
const useDeleteRole = () => {
    const tenantIdOrKey = useSelectorCustom("tenant.id");
    const [deleteMutation, { data, loading: loadingMutation, error: errorMutation, client }] = useMutation(DELETE_ROLE);
    const [succeeded, setSucceeded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const { removeRole } = useContext(RemoveRoleContext);
    const refetch = () => {
        client === null || client === void 0 ? void 0 : client.refetchQueries({ include: ["principals"] });
    };
    const deleteRoleMutation = async (inputs) => {
        const deleteOneRole = async (inputs) => {
            const { siteId: siteIdOrName, principalId, roleId } = inputs;
            try {
                await deleteMutation({
                    variables: {
                        input: {
                            tenantIdOrKey,
                            siteIdOrName,
                            principalId,
                            roleId,
                        },
                    },
                });
            }
            catch (e) {
                setSucceeded(false);
            }
        };
        const deleteArrayOfRoles = async (input) => {
            const { siteId: siteIdOrName, principalId, roleIds } = input;
            setSucceeded(false);
            try {
                await Promise.all(roleIds.map((roleId) => deleteMutation({
                    variables: {
                        input: {
                            tenantIdOrKey,
                            siteIdOrName,
                            principalId,
                            roleId,
                        },
                    },
                })));
            }
            catch (e) {
                setSucceeded(false);
            }
        };
        if ("roleIds" in inputs) {
            await deleteArrayOfRoles(inputs);
        }
        else {
            await deleteOneRole(inputs);
        }
    };
    useEffect(() => {
        if (data) {
            const succeeded = data.removeRoleAssignment !== null;
            if (succeeded) {
                removeRole(data.removeRoleAssignment.roleAssignment);
            }
            setSucceeded(succeeded);
        }
    }, [data]);
    useEffect(() => {
        if (loading !== loadingMutation) {
            setLoading(loadingMutation);
        }
    }, [loadingMutation]);
    useEffect(() => {
        if (error !== errorMutation) {
            setError(errorMutation);
            setSucceeded(false);
        }
    }, [errorMutation]);
    return [deleteRoleMutation, { loading, error, succeeded, refetch }];
};
export { useDeleteRole };
