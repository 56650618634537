import { useContext, useEffect, useState } from "react";
import { PrincipalContext } from "../components/principalProvider";
const formatPrincipals = (principals) => principals.map((principal) => ({
    key: principal.id,
    ...principal,
}));
/**
 * Custom hook to manage and format a list of principals.
 *
 * This hook uses the `PrincipalContext` to retrieve a list of grouped principals,
 * formats them into a `PrincipalTable` structure, and provides the formatted list.
 *
 * @returns {PrincipalTable[]} A stateful array containing the formatted list of principals.
 *
 * @example
 * const [principals] = usePrincipalList();
 *
 * @remarks
 * The hook listens for changes in the `groupedPrincipals` from the context and updates
 * the state accordingly.
 */
const usePrincipalList = () => {
    const context = useContext(PrincipalContext);
    const groupedPrincipals = context === null || context === void 0 ? void 0 : context.groupedPrincipals;
    const updateResizeTable = context.updateResizeTable;
    const [principals, setPrincipals] = useState([]);
    useEffect(() => {
        setPrincipals(formatPrincipals(groupedPrincipals));
    }, [groupedPrincipals]);
    return [principals, updateResizeTable];
};
export { usePrincipalList };
