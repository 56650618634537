import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Avatar, createTableColumn, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
import { DataGridBody, DataGrid, DataGridRow, DataGridHeader, DataGridCell, DataGridHeaderCell, } from "@fluentui-contrib/react-data-grid-react-window";
import { CheckmarkCircle24Regular } from "@fluentui/react-icons";
import { PrincipalType } from "@types";
import { TextTooltip } from "@components";
const useStyles = makeStyles({
    alreadyInTenant: {
        color: tokens.colorBrandForegroundLink,
        position: "absolute",
        left: "-2.5rem",
    },
    checkboxAlreadyInTenant: {
        "& > div > span": {
            display: "none",
        },
    },
    width: {
        width: "calc(100% - 2.8rem)", // 2rem for avatar and 0.8rem for column gap
    },
    userCellContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        columnGap: "0.8rem",
        width: "100%",
    },
    noSelectedRows: {
        "& > div > span > div > svg": {
            display: "none",
        },
    },
    noHeaderSelector: {
        "& > div > span": {
            display: "none",
        },
    },
});
const PrincipalsTable = forwardRef(({ filteredPrincipalItems, hiddenHeaderSelector, selectionChange, parentRef }, ref) => {
    const styles = useStyles();
    const [principalItems, setPrincipalItems] = useState([]);
    const [selectedRows, setSelectedRows] = useState(new Set([]));
    const [idsOfAlreadyInTenant, setIdsOfAlreadyInTenant] = useState(new Set([]));
    const [parentOffset, setParentOffset] = useState({
        width: 0,
        height: 0,
    });
    const onSelectionChange = (_, data) => {
        selectionChange([...data.selectedItems]);
        setSelectedRows(new Set([...data.selectedItems, ...idsOfAlreadyInTenant]));
    };
    const thereAreNoSelectedRows = selectedRows.size === idsOfAlreadyInTenant.size;
    useImperativeHandle(ref, () => ({
        addSelection: () => {
            setIdsOfAlreadyInTenant(new Set(selectedRows));
            setSelectedRows(new Set([]));
            const filteredPrincipalsItemsUpdated = filteredPrincipalItems.map((principalItem) => ({
                ...principalItem,
                alreadyInTenant: selectedRows.has(principalItem.id),
            }));
            setPrincipalItems(filteredPrincipalsItemsUpdated);
        },
    }));
    const columns = [
        createTableColumn({
            columnId: "user",
            compare: (a, b) => {
                return a.displayName.localeCompare(b.displayName);
            },
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (_jsxs("div", { className: styles.userCellContainer, children: [item.alreadyInTenant && (_jsx("div", { "data-testid": "add-users-already-in-tenant-icon", className: styles.alreadyInTenant, children: _jsx(CheckmarkCircle24Regular, {}) })), _jsx(Avatar, { "aria-label": item.displayName, color: "colorful", name: item.displayName }), _jsx("div", { className: styles.width, children: _jsx(TextTooltip, { text: item.displayName }) })] }));
            },
        }),
        createTableColumn({
            columnId: "type",
            compare: (a, b) => {
                return a.principalType.localeCompare(b.principalType);
            },
            renderHeaderCell: () => {
                return "Type";
            },
            renderCell: (item) => {
                return _jsx("div", { children: item.principalType === PrincipalType.USER ? "User" : "Application" });
            },
        }),
        createTableColumn({
            columnId: "domain",
            compare: (a, b) => {
                return a.email.localeCompare(b.email);
            },
            renderHeaderCell: () => {
                return "Domain";
            },
            renderCell: (item) => {
                return _jsx(TextTooltip, { text: item.email });
            },
        }),
    ];
    const renderRow = ({ item, rowId }, style) => (_jsx(DataGridRow, { className: mergeClasses(item.alreadyInTenant && styles.checkboxAlreadyInTenant), style: style, selectionCell: {
            checkboxIndicator: { "aria-label": "Select row" },
        }, children: ({ renderCell }) => _jsx(DataGridCell, { children: renderCell(item) }) }, rowId));
    useEffect(() => {
        const idsOfAlreadyInTenant = filteredPrincipalItems
            .map((item) => (item.alreadyInTenant ? item.id : null))
            .filter((id) => id !== null);
        setPrincipalItems([...filteredPrincipalItems]);
        setIdsOfAlreadyInTenant(new Set(idsOfAlreadyInTenant));
    }, [filteredPrincipalItems]);
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setParentOffset({
                    width: parentRef.current.offsetWidth - 15,
                    height: parentRef.current.offsetHeight - 35,
                });
            }
        };
        handleResize();
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        if (parentRef.current) {
            resizeObserver.observe(parentRef.current);
        }
        // Clean up
        return () => resizeObserver.disconnect();
    }, []);
    return (_jsxs(DataGrid, { items: principalItems, columns: columns, sortable: true, selectionMode: "multiselect", selectionAppearance: "none", selectedItems: selectedRows, onSelectionChange: onSelectionChange, getRowId: (item) => item.id, resizableColumns: true, columnSizingOptions: {
            user: { defaultWidth: parentOffset.width * 0.4, minWidth: 250 },
            domain: { defaultWidth: parentOffset.width * 0.4, minWidth: 300 },
            type: { defaultWidth: 75, minWidth: 75 },
        }, children: [_jsx(DataGridHeader, { children: _jsx(DataGridRow, { className: mergeClasses(thereAreNoSelectedRows && styles.noSelectedRows, hiddenHeaderSelector && styles.noHeaderSelector), selectionCell: { checkboxIndicator: { "aria-label": "Select all rows" } }, children: ({ renderHeaderCell }) => _jsx(DataGridHeaderCell, { children: renderHeaderCell() }) }) }), _jsx(DataGridBody, { itemSize: 45, height: parentOffset.height, children: renderRow })] }, parentOffset.width + parentOffset.height));
});
export default PrincipalsTable;
