import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, makeStyles, tokens, Tooltip } from "@fluentui/react-components";
import { ShieldPersonRegular } from "@fluentui/react-icons";
import { createColumn, TextTooltip } from "@components";
var UserRoles;
(function (UserRoles) {
    UserRoles[UserRoles["ServiceProvider"] = 0] = "ServiceProvider";
    UserRoles[UserRoles["TenantAdmin"] = 1] = "TenantAdmin";
    UserRoles[UserRoles["SiteAdmin"] = 2] = "SiteAdmin";
    UserRoles[UserRoles["JobDesigner"] = 3] = "JobDesigner";
    UserRoles[UserRoles["Consumer"] = 4] = "Consumer";
})(UserRoles || (UserRoles = {}));
const useStyles = makeStyles({
    lineHeight: {
        "& span:nth-child(2)": {
            lineHeight: "var(--lineHeightBase600)",
        },
    },
    tenantAdminShield: {
        color: tokens.colorStrokeFocus2,
    },
    textContainer: {
        paddingLeft: "0.5rem",
        width: "calc(100% - 2.8rem)", // 2rem for avatar and 0.8rem for column gap
    },
});
const createPersonaColumn = (columnId, headerText, showName) => {
    const createPersona = (principal, _) => {
        const styles = useStyles();
        const isTenantAdmin = principal.roleAssignments.some((role) => role.roleId === UserRoles.TenantAdmin);
        return (_jsxs(_Fragment, { children: [_jsx(Avatar, { name: principal.displayName, color: "colorful", badge: isTenantAdmin
                        ? {
                            status: "available",
                            icon: (_jsx(Tooltip, { content: "Tenant Admin", relationship: "label", hideDelay: 250, positioning: "before-bottom", children: _jsx(ShieldPersonRegular, { className: styles.tenantAdminShield, "data-testid": "persona-tenant-admin-icon" }) })),
                        }
                        : undefined }), showName && (_jsxs("div", { className: styles.textContainer, children: [" ", _jsx(TextTooltip, { text: principal.displayName })] }))] }));
    };
    return createColumn(columnId, headerText, createPersona, true);
};
export { createPersonaColumn };
