import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { TabList, Tab } from "@fluentui/react-components";
import { rootPage as useRootStyles } from "@styles";
import { useRolTabState } from "../../hooks";
const RolTabs = () => {
    const rootStyles = useRootStyles();
    const [usersGroups, selectedUsersGroup, updateSelectedUsersGroup] = useRolTabState();
    useEffect(() => {
        updateSelectedUsersGroup(usersGroups[0]);
    }, []);
    return (_jsx("div", { className: rootStyles.tabList, children: _jsx(TabList, { defaultSelectedValue: selectedUsersGroup.name, appearance: "transparent", "data-testid": "grouping-pane", children: usersGroups.map((group) => {
                return (_jsxs(Tab, { value: group.name, onClick: () => updateSelectedUsersGroup(group), children: [`${group.name}`, group.length !== undefined && ` (${group.length})`] }, group.name));
            }) }) }));
};
export { RolTabs };
