import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useQueryPrincipals } from "../../hooks/useQueryPrincipals";
import { UpdateRoleAction, UsersGroupName } from "../../enums";
import { UserRoles } from "@lib/redux";
const initialUsersGroups = Object.values(UsersGroupName).map((name) => ({
    name,
    length: 0,
}));
const principalsGroupedDict = {
    [UsersGroupName.Assigned]: [],
    [UsersGroupName.SiteAdministrators]: [],
    [UsersGroupName.JobDesigners]: [],
    [UsersGroupName.Consumers]: [],
    [UsersGroupName.Unassigned]: [],
};
let updatedPrincipals = [];
/**
 * Providers for the principal context
 */
const StatusQueryContext = createContext({ loading: true, principals: [], error: undefined });
const PrincipalContext = createContext({ groupedPrincipals: [], updateResizeTable: (token) => { } });
const UsersGroupContext = createContext({ usersGroups: initialUsersGroups, selectedUsersGroup: initialUsersGroups[0], updateSelectedUsersGroup: () => { } });
const UpdateSiteSelectedContext = createContext({ updateSiteSelected: () => { } });
const SelectedSiteContext = createContext({ selectedSite: { id: "", name: "" } });
const AddRoleContext = createContext({ addRole: () => { } });
const RemoveRoleContext = createContext({ removeRole: () => { } });
/**
 * PrincipalProvider component that provides context for principals, users groups, and selected site.
 *
 * @param {PropsWithChildren<{}>} The children components to be wrapped by the provider.
 *
 * @returns {JSX.Element} The context providers wrapping the children components.
 *
 * @context {StatusQueryContext} Provides the loading status and any errors encountered during the query.
 * @context {PrincipalContext} Provides the grouped principals and a flag indicating if principals need to be updated.
 * @context {UsersGroupContext} Provides the users groups, the selected users group, and a function to update the selected users group.
 * @context {SelectedSiteContext} Provides the selected site and a function to update the selected site.
 */
const PrincipalProvider = ({ children }) => {
    const [selectedSite, setSelectedSite] = useState({ id: "", name: "" });
    const [groupedPrincipals, setGroupedPrincipals] = useState([]);
    const [updatedRoles, setUpdatedRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [usersGroups, setUsersGroups] = useState(initialUsersGroups);
    const [selectedUsersGroup, setSelectedUsersGroup] = useState(usersGroups[0]);
    const [resizeTable, setResizeTable] = useState(0);
    const [error, setError] = useState();
    const { principals, done, loading: loadingFromApi, loadingPrincipalsFromAzure, error: errorFromQuery, } = useQueryPrincipals();
    const updateSiteSelected = useCallback((site) => {
        setSelectedSite({ id: site.id, name: site.name });
    }, []);
    const updateSelectedUsersGroup = useCallback((usersGroup) => {
        setSelectedUsersGroup(usersGroup);
    }, []);
    const updateResizeTable = useCallback((token) => {
        setResizeTable(token);
    }, []);
    const updateRole = (action, updatedRole) => {
        const roleToUpdate = { action, role: updatedRole };
        const roleAddIndex = updatedRoles.findIndex((updateRole) => updateRole.action === UpdateRoleAction.Add &&
            updateRole.role.principalId === updatedRole.principalId &&
            updateRole.role.roleId === updatedRole.roleId &&
            updateRole.role.siteId === updatedRole.siteId);
        if (roleAddIndex !== -1) {
            const newUpdatedRoles = [...updatedRoles];
            newUpdatedRoles.splice(roleAddIndex, 1);
            setUpdatedRoles(newUpdatedRoles);
        }
        else {
            setUpdatedRoles([...updatedRoles, roleToUpdate]);
        }
    };
    const removeRole = (updatedRole) => {
        updateRole(UpdateRoleAction.Delete, updatedRole);
    };
    const addRole = (updatedRole) => {
        updateRole(UpdateRoleAction.Add, updatedRole);
    };
    const updatePrincipals = () => {
        if (updatedRoles.length > 0) {
            const newUpdatedPrincipals = [...updatedPrincipals].map((principal) => {
                const principalRoles = [...principal.roleAssignments];
                const updatedRolesForPrincipal = updatedRoles.filter((role) => role.role.principalId === principal.id);
                updatedRolesForPrincipal.forEach((role) => {
                    if (role.action === UpdateRoleAction.Add) {
                        principalRoles.push(role.role);
                    }
                    else {
                        const roleIndex = principalRoles.findIndex((principalRole) => principalRole.roleId === role.role.roleId &&
                            principalRole.siteId === role.role.siteId &&
                            principalRole.principalId === role.role.principalId);
                        principalRoles.splice(roleIndex, 1);
                    }
                });
                return { ...principal, roleAssignments: principalRoles };
            });
            setUpdatedRoles([]);
            updatedPrincipals = [...newUpdatedPrincipals];
            processPrincipals(newUpdatedPrincipals);
        }
    };
    const groupPrincipals = useCallback((principals, usersGroupType, selectedSiteId) => {
        let groupedPrincipals;
        switch (usersGroupType) {
            case UsersGroupName.Assigned:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.siteId == selectedSiteId || (!selectedSiteId && role.siteId));
                });
                break;
            case UsersGroupName.SiteAdministrators:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.roleId == UserRoles.SiteAdmin && (role.siteId == selectedSiteId || !selectedSiteId));
                });
                break;
            case UsersGroupName.JobDesigners:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.roleId == UserRoles.JobDesigner && (role.siteId == selectedSiteId || !selectedSiteId));
                });
                break;
            case UsersGroupName.Consumers:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.roleId == UserRoles.Consumer && (role.siteId == selectedSiteId || !selectedSiteId));
                });
                break;
            case UsersGroupName.Unassigned:
                if (selectedSiteId) {
                    groupedPrincipals = principals.filter((principal) => {
                        return !principal.roleAssignments.some((role) => role.siteId === selectedSiteId);
                    });
                }
                else {
                    groupedPrincipals = principals.filter((principal) => {
                        return !principal.roleAssignments.some((role) => !!role.siteId);
                    });
                }
                break;
            default:
                groupedPrincipals = [];
        }
        return groupedPrincipals;
    }, []);
    const processPrincipals = (principals) => {
        usersGroups.forEach((group) => {
            principalsGroupedDict[group.name] = groupPrincipals(principals, group.name, selectedSite.id);
        });
        const newUsersGroupsLength = usersGroups.map((usersGroup) => ({
            ...usersGroup,
            length: principalsGroupedDict[usersGroup.name].length,
        }));
        setUsersGroups(newUsersGroupsLength);
    };
    useEffect(() => {
        processPrincipals(principals);
        updatedPrincipals = [...principals];
    }, [principals]);
    useEffect(() => {
        processPrincipals(updatedPrincipals);
    }, [groupPrincipals, selectedSite]);
    useEffect(() => {
        updatePrincipals();
        setGroupedPrincipals([...principalsGroupedDict[selectedUsersGroup.name]]);
    }, [selectedUsersGroup, selectedSite, resizeTable]);
    useEffect(() => {
        if (done) {
            setLoading(false);
        }
    }, [principals, done]);
    useEffect(() => {
        if (loading !== (loadingPrincipalsFromAzure || loadingFromApi)) {
            setLoading(loadingPrincipalsFromAzure || loadingFromApi);
        }
    }, [loadingPrincipalsFromAzure, loadingFromApi, loading]);
    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [errorFromQuery, error]);
    const statusQueryContextValue = useMemo(() => ({
        loading,
        principals,
        error,
    }), [principals, loading, error]);
    const principalContextValue = useMemo(() => ({
        groupedPrincipals: [...groupedPrincipals],
        updateResizeTable,
    }), [groupedPrincipals, updateResizeTable]);
    const usersGroupContextValue = useMemo(() => ({
        usersGroups,
        selectedUsersGroup,
        updateSelectedUsersGroup,
    }), [usersGroups, selectedUsersGroup, updateSelectedUsersGroup]);
    const selectedSiteContextValue = useMemo(() => ({
        selectedSite,
    }), [selectedSite]);
    const UpdateSiteSelectedContextValue = useMemo(() => ({
        updateSiteSelected,
    }), [updateSiteSelected]);
    const addRemoveRoleContextValue = useMemo(() => ({
        addRole,
    }), [updatedRoles]);
    const removeRoleContextValue = useMemo(() => ({
        removeRole,
    }), [updatedRoles]);
    return (_jsx(StatusQueryContext.Provider, { value: statusQueryContextValue, children: _jsx(UpdateSiteSelectedContext.Provider, { value: UpdateSiteSelectedContextValue, children: _jsx(SelectedSiteContext.Provider, { value: selectedSiteContextValue, children: _jsx(UsersGroupContext.Provider, { value: usersGroupContextValue, children: _jsx(PrincipalContext.Provider, { value: principalContextValue, children: _jsx(AddRoleContext.Provider, { value: addRemoveRoleContextValue, children: _jsx(RemoveRoleContext.Provider, { value: removeRoleContextValue, children: children }) }) }) }) }) }) }));
};
export { AddRoleContext, PrincipalContext, PrincipalProvider, RemoveRoleContext, SelectedSiteContext, StatusQueryContext, UsersGroupContext, UpdateSiteSelectedContext, };
